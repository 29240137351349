import { getFaceIdsByBatch, getThumbBatch } from "../../data/faces";
import { useCallback, useEffect, useState } from "react";
import FaceItem from "./FaceItem";
const FaceBatch = (props) => {
    const { tabId, id, currentPrice } = props;
    const [batchThumb, setBatchThumb] = useState(
        {
            loaded: false,
            thumbArr: [],
        }
    );
    const loadBatch = useCallback(async (tabId, id) => {
        const res = await getThumbBatch(tabId, id);
        if (res) {
            setBatchThumb(
                {
                    loaded: true,
                    thumbArr: res.data.thumbArr,
                }
            );
        }
    }, [])
    useEffect(() => {
        loadBatch(tabId, id);
    }, [id, tabId, loadBatch])

    const faceIds = getFaceIdsByBatch(id);
    return (
        <>
            {faceIds.map(faceId =>
                <FaceItem tabId={tabId} batchId={id} id={faceId} key={faceId} currentPrice={currentPrice} batchThumb={batchThumb} loadBatch={loadBatch} />)
            }
        </>
    )
}

export default FaceBatch;