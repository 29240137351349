import { useEffect, useState } from "react";
import { getFaceImg } from "../../data/faces";
const PreviewFace = (props) => {
    const { tabId, faceId } = props;
    const [loading, setLoading] = useState(true);
    const [imgData, setImgData] = useState("");
    useEffect(() => {
        async function loadPreviewImg() {
            // getFaceImg();
            // setLoading(true);
            getFaceImg(tabId, faceId).then(res => {
                if (res && res.data) {
                    // console.log(res.data.image)
                    // console.log('got img, set state')
                    setImgData(res.data.image);
                }
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                setLoading(false);
            })
        }
        loadPreviewImg();
    });

    return (<img src={loading ? '/loading.gif' : `data:image/jpeg;base64,${imgData}`} className="preview-img" alt="preview img" />);
}
export default PreviewFace;