import { useState } from "react";

const Header = () => {
    const [openNav, setOpenNav] = useState(false);
    // const variants = {
    //     open: { opacity: 1, y: 0 },
    //     closed: { opacity: 0, y: '-100%' }
    // }
    const handleToggle = () => {
        setOpenNav(open => !open);
    }
    // document.addEventListener('click', () => {
    //     handleToggle();
    // })
    return (
        <div className="header">
            <header className="header_pc">
                <figure className="header_logo_box">
                    <img src={'/icon-1.png'} alt="face.ai logo" className="header_logo" />
                    <p className="header_text"> face.ai</p>
                </figure>
                <nav className="navigation">
                    <ul className="header_menu">
                        <li><a href="#home">Home</a></li>
                        {/* <li><a href="#inscription">Inscription</a></li> */}
                        <li><a href="#QA">Q&A</a></li>
                        <li><a href="/member">Membership</a></li>
                        {/* <li><a href="#QA">Q&A</a></li> */}
                        <li><a href="https://x.com/wwwfaceai" target="_blank" rel="noreferrer">Contact</a></li>

                    </ul>
                </nav>
            </header>

            <header className="header_mobile">
                <figure className="header_logo_box">
                    <img src={'/icon-1.png'} alt="face.ai logo" className="header_logo" />
                    <p className="header_text"> face.ai</p>
                </figure>
                <div style={{ display: openNav ? 'flex' : 'none' }} className="mobileNavContainer">
                    <ul className="mobile_header_menu header_menu">
                        <li><a href="#home">Home</a></li>
                        {/* <li><a href="#inscription">Inscription</a></li> */}
                        <li><a href="#QA">Q&A</a></li>
                        <li><a href="/member">Membership</a></li>
                        <li><a href="https://x.com/wwwfaceai" target="_blank" rel="noreferrer">Contact</a></li>
                    </ul>
                </div>
                <img onClick={handleToggle} src={openNav ? '/close.svg' : '/menu.svg'} alt="-"
                    style={{ top: 25, right: 25, position: 'absolute' }} />



            </header>
        </div>
    );
}

export default Header;