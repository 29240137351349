import axios from "axios";
let reqInstance = axios.create({
    headers: {
        Authorization: process.env.REACT_APP_AUTH_KEY
    }
});

const faceIds = [];
for (let i = 0; i < 1024; i++) {
    faceIds.push(i);
}
export const getFaceIds = () => {
    return faceIds;
}

const tabIds = [];
for (let i = 1; i <= 2; i++) {
    tabIds.push(i);
}
export const getTabIds = () => {
    return tabIds;
}

const batchIds = [];
for (let i = 1; i <= 32; i++) {
    batchIds.push(i);
}
export const getBatchIds = () => {
    return batchIds;
}

export const getFaceIdsByBatch = (batchId) => {
    const faceIds = [];
    for (let i = (batchId - 1) * 32; i < batchId * 32; i++) {
        faceIds.push(i);
    }
    return faceIds;
}

export const getThumb = async (tabId, faceId) => {
    let res
    try {
        res = await reqInstance.get(process.env.REACT_APP_SERVER_ADDRESS + '/thumb?tab_id=' + tabId + '&face_id=' + faceId);
    } catch (e) {
        console.log(e)
    }
    // console.log(res);
    return res;
}
export const getThumbBatch = async (tabId, batchId) => {
    let res;
    try {
        res = await reqInstance.get(process.env.REACT_APP_SERVER_ADDRESS + '/thumb/batch?tab_id=' + tabId + '&batch_id=' + batchId);
    } catch (e) {
        console.log(e)
    }
    // console.log(res);
    return res;
}


export const checkPaid = async (tabId, faceId) => {
    let ret;
    try {
        const res = await reqInstance.get(process.env.REACT_APP_SERVER_ADDRESS + '/stripe/intent?tab_id=' + tabId + '&face_id=' + faceId);
        ret = { success: true, data: res.data.result }
    } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
            ret = { success: false, data: e.response.data.message + " Please contact https://x.com/wwwfaceai for help" }
        } else {
            ret = { success: false, data: "Failed to check payment status, Please contact https://x.com/wwwfaceai for help" }
        }
    }
    return ret;
}

export const uploadFace = async (formData) => {
    try {
        await reqInstance.post(process.env.REACT_APP_SERVER_ADDRESS + '/face', formData)
    } catch (res) {
        if (res && res.response && res.response.data && res.response.data.message) {
            return res.response.data.message
        }
        return res
    }
}

export const getFaceImg = async (tabId, faceId) => {
    let res
    try {
        res = await reqInstance.get(process.env.REACT_APP_SERVER_ADDRESS + '/face?tab_id=' + tabId + '&face_id=' + faceId);
    } catch (e) {
        console.log(e)
    }
    // console.log(res);
    return res;
}

const luckyNumer = [8, 9, 11, 22, 33, 44, 55, 66, 77, 88, 99, 100, 111, 200, 222, 300, 333, 400, 444, 500, 520, 555, 600, 666, 700, 777, 800, 888, 900, 999, 1000];
const BadNumber = [13, 14];

export const FIRST = 1;
export const SECOND = 2;
export const THIRD = 3;

export const getPriceByClass = (classId) => {
    switch (classId) {
        case FIRST:
            return process.env.REACT_APP_FACE_PRICE_FIRST || 5941;
        case SECOND:
            return process.env.REACT_APP_FACE_PRICE_SECOND || 941;
        case THIRD:
            return process.env.REACT_APP_FACE_PRICE_THIRD || 94;
        default:
            return process.env.REACT_APP_FACE_PRICE_THIRD || 94;
    }
}

export const getClass = (id) => {
    if (luckyNumer.includes(id)) {
        return FIRST
    }
    if (BadNumber.includes(id)) {
        return THIRD
    }
    if (id < 32) {
        return FIRST
    }
    if (id < 512) {
        return SECOND
    }
    return THIRD
}

export const selectPriceByClass = (currentPrice, classId) => {
    switch (classId) {
        case FIRST:
            return currentPrice ? currentPrice.first : 5941;
        case SECOND:
            return currentPrice ? currentPrice.second : 941;
        case THIRD:
            return currentPrice ? currentPrice.third : 94;
        default:
            return currentPrice ? currentPrice.first : 5941;
    }
}
export const getCssClassById = (id) => {
    switch (getClass(id)) {
        case FIRST:
            return 'face_first';
        case SECOND:
            return 'face_second';
        case THIRD:
            return 'face_third';
        default:
            return '';
    }
}

export const tabItems = [
    {
        label: 'Seed Membership',
        key: '1',
    },
    {
        label: 'M2',
        key: '2',
        disabled: true,
    },
    {
        label: 'M3',
        key: '3',
        disabled: true,
    },
    {
        label: 'M4',
        key: '4',
        disabled: true,
    },
    {
        label: 'M5',
        key: '5',
        disabled: true,
    },
    {
        label: 'M6',
        key: '6',
        disabled: true,
    },
    {
        label: 'M7',
        key: '7',
        disabled: true,
    },
    {
        label: 'M8',
        key: '8',
        disabled: true,
    },
    {
        label: 'M9',
        key: '9',
        disabled: true,
    },
    {
        label: 'M10',
        key: '10',
        disabled: true,
    },
]

export const getThumbKey = (tabId, faceId) => {
    const thumbKey = 'faceai_tab_' + tabId + '_face_' + faceId + '_thumb';
    return thumbKey;
}