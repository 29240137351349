import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
// import { createIntent } from '../data/stripe';
import { checkPaid, } from '../../data/faces';
import { Button } from 'antd';

const StripeCheckoutForm = (props) => {
    const { tabId, batchId, faceId, closeModal, clearInput, setErrMessage, stripeClientSecret, loadBatch } = props;

    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);
    const [elementReady, setElementReady] = useState(false);
    const [timeLeft, setTimeLeft] = useState(300);

    useEffect(() => {
        if (timeLeft === 0) {
            // console.log('time out, close deal')
            clearInput();
        }
    }, [timeLeft, clearInput])

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft > 0 ? timeLeft - 1 : timeLeft);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleSubmit = async (event, clientSecret) => {
        setProcessing(true);
        try {
            event.preventDefault();
            if (elements == null) {
                return;
            }
            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();
            if (submitError) {
                // Show error to your customer
                setErrMessage(submitError.message);
                return;
            }

            const { error, paymentIntent } = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                clientSecret,
                confirmParams: {
                    return_url: process.env.REACT_APP_FRONTEND_DOMAIN,
                },
                redirect: 'if_required'
            });

            if (error) {
                setErrMessage(error.message);
            } else {
                if (paymentIntent.status === 'succeeded') {
                    const res = await checkPaid(tabId, faceId);
                    loadBatch(tabId, batchId);
                    if (res.success === true) {
                        closeModal();
                    } else {
                        setErrMessage(res.data)
                    }
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            setProcessing(false)
        }
    };
    const formatTimeMsg = (sec) => {
        let time = '';
        // if (sec > 60) {
        //     time += (sec / 60).toString() + ' min ';
        // }
        // time += (sec % 60).toString() + ' s';
        time = sec + ' s'
        return 'Plase complete payment in ' + time;
    }
    return (
        <form>
            {/* <p style={{ fontWeight: 'bold' }}>Price: ${getPriceByClass(getClass(faceId))}</p> */}
            {stripeClientSecret !== '' && <p style={{ fontWeight: 'bold' }}>{formatTimeMsg(timeLeft)}</p>}
            <PaymentElement onReady={() => setElementReady(true)} />

            <Button disabled={!stripe || !elements || processing || !elementReady || timeLeft <= 0 || stripeClientSecret === ''} className='stripe_button'
                onClick={(e) => { handleSubmit(e, stripeClientSecret) }}>
                {processing ? 'Processing' : 'Pay'}
            </Button>
        </form>
    );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);


const StripeElement = (props) => {
    const { tabId, batchId, faceId, closeModal, clearInput, priceInCent, setErrMessage, stripeClientSecret, loadBatch } = props;
    const options = {
        mode: 'payment',
        amount: priceInCent,
        currency: 'usd',
    };
    return (
        <Elements stripe={stripePromise} options={options}>
            <StripeCheckoutForm tabId={tabId} batchId={batchId} faceId={faceId} closeModal={closeModal} clearInput={clearInput}
                setErrMessage={setErrMessage} stripeClientSecret={stripeClientSecret} loadBatch={loadBatch} />
        </Elements>
    );
}

export default StripeElement;