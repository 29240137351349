import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

const QA = () => {
    const items = [
        {
            key: '1',
            label: <span className="qa_question">Why Choose the Face AI Membership Program?</span>,
            children: <p className="qa_answer">In the future, artificial intelligence will permeate every aspect of our lives in unimaginable ways.
                Through the Face AI Membership Program, your face becomes a key to unlocking this new world, a portal for transmitting value and
                imprinting your identity and memories into the fabric of digital eternity. This is a unique opportunity to etch the likeness
                of yourself or your loved ones into the milestones of future technology.</p>,
        },
        {
            key: '2',
            label: <span className="qa_question">What Does Your Contribution Mean?</span>,
            children: <div>
                <p className="qa_answer">Pioneering Role: Your face will be among the first members in the Face AI database, serving as a template for learning and laying the foundation for a new AI world.</p>
                <p className="qa_answer">Membership: You will receive our membership services, including AI training, AI consulting, and AI software customization services.</p>
                <p className="qa_answer">Value Investment: As early participants, your image may become an ambassador for future brands, a distinction that also holds potential economic benefits.</p>

            </div>,
        },
        {
            key: '3',
            label: <span className="qa_question">How to Join?</span>,
            children: <div>
                <p className="qa_answer">Choose the face you wish to upload — your own, a loved one's, an idol, or a trusted brand logo. Upload this image to our AI Membership Program, 
                and it will be used to train our AI models, becoming part of the new AI world, and you will automatically become our Member.
                </p>
                <a className="qa_answer" style={{ textDecorationLine: 'underline' }} href="https://face.ai/member" >https://face.ai/member</a>
            </div>,
        },
        {
            key: '4',
            label: <span className="qa_question">How Will Your Membership Fee Be Used?</span>,
            children: <p className="qa_answer">Each face, every smile, will be transformed into the driving force for AI learning,
                aiding not just in understanding human diversity better but also in creating a more personable and personalized AI service experience.
                Your membership will directly support the advancement of AI technology, ensuring it progresses on a path that is right and responsible.</p>,
        },

    ];
    return (
        <div className="qa_main_container" id={'QA'}>
            <div className="qa_heading">
                <img src={'/icon-1.png'} alt="face.ai logo" className="qa_heading_img" />
                <p className="qa_heading_text">Q&A</p>

            </div>
            <Collapse defaultActiveKey={['1']} ghost items={items} className="qa_content"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: "whitesmoke" }} />} />
        </div>
    )
}

export default QA;
