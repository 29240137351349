import { useState } from 'react';
import { tabItems } from '../data/faces';
import FaceGrid from './facegrid/FaceGrid';
// import NodeFaceGrid from './nodefacegrid/FaceGrid';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
// import Facenode from '../node/facenode';
const Credit = () => {
    const [curTab, setCurTab] = useState('1');
    const onTabClick = (e) => {
        setCurTab(e.key);
    };
    return (
        <div className='body'>
            <div className='heading'>
                <img src='/icon-1.png' className='logo' alt='logo'></img>
                <p style={{ fontFamily: "YoungSerif", fontSize: 'x-large', marginTop: '10px', color: 'whitesmoke' }}>{'Face AI Membership valued by AI'}</p>
            </div>
            <div style={{ width: '100%' }}>
                <a className='back_home' href='/'>
                    <ArrowLeftOutlined className='back_home_logo' />
                    <p className='back_home_text'>Back to Home</p>
                </a>
            </div>

            {/* <FaceGrid tabId={1} /> */}
            <div className='menu_container'>
                <Menu onClick={onTabClick} selectedKeys={[curTab]} mode="horizontal" items={tabItems} className='credit_menu' />
            </div>
            {/* <Menu onClick={onTabClick} selectedKeys={[curTab]} mode="horizontal" items={tabItems} className='credit_menu'/> */}


            {/* node membership */}
            {/* {curTab === '1' && <Facenode />} */}
            {/* {curTab === '1' && <NodeFaceGrid tabId={2} />} */}


            {/* seed membership */}
            {/* {curTab === '2' && <FaceGrid tabId={1} />} */}
            {curTab === '1' && <FaceGrid tabId={1} />}

            {curTab === '3' && <FaceGrid tabId={3} />}
            {curTab === '4' && <FaceGrid tabId={4} />}
            {curTab === '5' && <FaceGrid tabId={5} />}
            {curTab === '6' && <FaceGrid tabId={6} />}
            {curTab === '7' && <FaceGrid tabId={7} />}
            {curTab === '8' && <FaceGrid tabId={8} />}
            {curTab === '9' && <FaceGrid tabId={9} />}
            {curTab === '10' && <FaceGrid tabId={10} />}
        </div>
    )
}

export default Credit;