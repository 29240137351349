import axios from "axios";
let reqInstance = axios.create({
    headers: {
        Authorization: process.env.REACT_APP_AUTH_KEY
    }
});
export const getCurrentTime = async () => {
    let ret
    try {
        const res = await reqInstance.get(process.env.REACT_APP_SERVER_ADDRESS + '/time');
        ret = { success: true, data: res.data }
    } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
            ret = { success: false, data: e.response.data.message }
        } else {
            ret = { success: false, data: "Failed to get time" }
        }
    }
    return ret;
}