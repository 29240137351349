import { useEffect, useState } from "react";
import { Image, Tag } from 'antd';
import { getCssClassById } from "../../data/faces";
import PreviewFace from "./PreviewFace";
import { LikeOutlined, MessageOutlined, SmileOutlined } from "@ant-design/icons";
import FaceUploadModal from "./FaceUploadModal";
const FaceItem = (props) => {
    const { tabId,batchId, id, currentPrice, batchThumb, loadBatch } = props;
    const [thumb, setThumb] = useState(undefined)
    const [ownerName, setOwnerName] = useState('');
    const [sponsorName, setSponsorName] = useState('');
    const [xAccount, setXAccount] = useState('');
    const [ownFaceFlag, setOwnFaceFlag] = useState(true);
    const [showThumbInfo, setShowThumbInfo] = useState(false);

    // console.log('delay is ', delay)
    useEffect(() => {
        async function loadThumb(batchThumb, faceId) {
            if (batchThumb.loaded) {
                let hasBought = false;
                batchThumb.thumbArr.forEach(thumb => {
                    if (thumb.FaceId === faceId) {
                        hasBought = true;
                        setThumb(thumb.ThumbData);
                        setOwnerName(thumb.OwnerName)
                        setSponsorName(thumb.SponsorName);
                        setXAccount(thumb.XAccount);
                        setOwnFaceFlag(thumb.OwnFaceFlag === 'true' ? true : false)
                    }

                });
                if (!hasBought) {
                    setThumb('')
                }
            }
        }
        loadThumb(batchThumb, id);
        // console.log(batchThumb)
    }, [batchThumb, id])
    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        if (thumb === "") {
            setModalOpen(true);
        }
    };

    let img = <></>
    if (thumb === undefined) {
        // img = <img src="/loading.webp" height={32} width={32} alt="loading" />
        img = <span style={{ color: 'grey' }}>-</span>
    } else if (thumb === "") {
        img = <span>{id}</span>
    } else {
        img = (
            <div className="thumb_img">
                <Image
                    width={32}
                    preview={{
                        imageRender: () => (
                            <PreviewFace tabId={tabId} faceId={id} />
                        ),
                        // toolbarRender: () => null,
                    }}
                    src={`data:image/jpeg;base64,${thumb}`}
                    alt={id}
                    onMouseEnter={() => { setShowThumbInfo(true) }}
                    onMouseLeave={() => { setShowThumbInfo(false) }}
                />

            </div>
        )
    }

    return (
        <>
            <div className={"face" + (thumb === undefined ? '' : ' ' + getCssClassById(id))} onClick={showModal} key={id} >
                {img}
                {thumb && showThumbInfo &&
                    <div className="thumb_info">
                        <img className="thumb_info_img" src={`data:image/jpeg;base64,${thumb}`} alt={id}></img>
                        <div className="thumb_info_container">
                            <div className="thumb_info_text">
                                <Tag color="whitesmoke" style={{ color: 'black' }} icon={<SmileOutlined />}>Face</Tag>
                                <span>{ownerName}</span>
                            </div>
                            {ownFaceFlag && xAccount &&
                                <div className="thumb_info_text">
                                    <Tag color="whitesmoke" style={{ color: 'black' }} icon={<MessageOutlined />}>X Username</Tag>
                                    <span>{xAccount}</span>
                                </div>
                            }
                            {!ownFaceFlag &&
                                <div className="thumb_info_text">
                                    <Tag color="whitesmoke" style={{ color: 'black' }} icon={<LikeOutlined />}>Seeder</Tag>
                                    <span style={{ padding: '0px', margin: 0 }}>{sponsorName}</span>
                                </div>
                            }
                            {!ownFaceFlag && xAccount &&
                                <div className="thumb_info_text">
                                    <Tag color="whitesmoke" style={{ color: 'black' }} icon={<MessageOutlined />}>Seeder X Username</Tag>
                                    <span>{xAccount}</span>
                                </div>
                            }
                        </div>
                    </div >
                }
            </div>
            <FaceUploadModal open={modalOpen} setOpen={setModalOpen} tabId={tabId} batchId={batchId} faceId={id} currentPrice={currentPrice} loadBatch={loadBatch} />
        </>
    )
}
export default FaceItem;    