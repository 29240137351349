import { Modal, Upload, Input, Radio, Button } from "antd";
import ImgCrop from "antd-img-crop";
import StripeElement from "./StripeElement";
import { useState } from "react";
import { getClass, selectPriceByClass } from "../../data/faces";
import { isImgFile } from "../../util/file";
import { createIntent } from "../../data/stripe";
import { useEffect } from "react";

const FaceUploadModal = (props) => {
    const { open, setOpen, tabId, batchId, faceId, currentPrice, loadBatch } = props;
    const [fileList, setFileList] = useState([])
    const [uploadImg, setUploadImg] = useState(undefined);
    const [errMessage, setErrMessage] = useState("");
    const [ownerName, setOwnerName] = useState('');
    const [sponsorName, setSponsorName] = useState('');
    const [xAccount, setXAccount] = useState('');
    const [ownFaceFlag, setOwnFaceFlag] = useState(true);
    const [priceInCent, setPriceInCent] = useState(0);
    const [confirmed, setConfirmed] = useState(false);
    const [stripeClientSecret, setStripeClientSecret] = useState('');
    const [referrenceCode, setReferrenceCode] = useState('');
    useEffect(() => {
        const init = async () => {
            // const ret = await getCurrentTime()
            // if (ret.success) {
            //     setPriceInCent(100 * selectPriceByClass(ret.data.current_price_1, ret.data.current_price_2, ret.data.current_price_3, getClass(faceId)));
            // }
            setPriceInCent(100 * selectPriceByClass(currentPrice, getClass(faceId)))
        }
        init();
    }, [faceId, currentPrice])
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const handleUpload = async (option) => {
        const file = option.file
        option.onSuccess(file)
    }
    const clearInput = () => {
        setUploadImg(undefined);
        setFileList([]);
        setConfirmed(false);
    }

    const onChange = ({ fileList: newFileList }) => {
        // console.log('newFileList:', newFileList)
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        return false
    };

    const beforeUpload = (file) => {
        const isImg = isImgFile(file.type);
        // console.log('file:', file);
        // console.log('img file size:', file.size);
        if (!isImg) {
            setErrMessage("File type not supported");
            return false;
        }
        if (file.size > 20 * 1024 * 1024) {
            setErrMessage("Image exceeds limit 20M");
            return false;
        }
        setUploadImg(file);
        setErrMessage("");
        return true;
    }

    const onRadioChange = (e) => {
        setOwnFaceFlag(e.target.value);
    }

    const handleCreateIntent = async () => {
        const formData = new FormData();
        formData.append('tab_id', tabId);
        formData.append('face_id', faceId);
        formData.append('price_in_cent', priceInCent);
        formData.append('face_img', uploadImg);
        formData.append('owner_name', ownerName);
        formData.append('sponsor_name', sponsorName);
        formData.append('x_account', xAccount);
        formData.append('own_face_flag', ownFaceFlag);
        formData.append('referrence_code', referrenceCode);
        const res = await createIntent(formData)
        if (res.success === true) {
            // console.log('create intent ok, data:', res.data);
            const { client_secret } = res.data;
            setStripeClientSecret(client_secret)

        } else {
            setErrMessage(res.data)
            clearInput();
            // sessionStorage.removeItem(getThumbKey(tabId, faceId))
        }
    }

    const canConfirm = () => {
        if (fileList.length === 1 && errMessage === '') {
            return true;
        }
        return false
    }
    const handleConfirm = () => {
        if (canConfirm()) {
            setConfirmed(true);
            handleCreateIntent();
        } else {
            setErrMessage('Invalid Face Image')
        }
    }
    const handleRefferenceCode = (e) => {
        setReferrenceCode(e.target.value);
    }
    return (
        <>
            <Modal title="Hold Spot" open={open} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <div>
                    <ul>
                        <li>Support Image format: PNG, JPG, JPEG, GIF</li>
                        <li>Image size should not exceed 20M</li>
                        <li>You can hold a spot for yourself, your loved ones, your favorite stars or brands, anyone.</li>
                        <li>Leave your X account if you would like to be reached</li>
                    </ul>
                </div>
                <p style={{ fontWeight: 'bold' }}>Price: ${priceInCent / 100}</p>
                <Radio.Group onChange={onRadioChange} value={ownFaceFlag} disabled={confirmed}>
                    <Radio value={true}>For myself</Radio>
                    <Radio value={false}>For others</Radio>
                </Radio.Group>
                <div className='face_info_input_group'>
                    <div className='face_info_input'>
                        <div className='face_name_label'>
                            <span>Face:</span>
                            {/* <div className='face_name_checkbox'>
                                <Checkbox checked={ownFaceFlag} onChange={(e) => setOwnFaceFlag(e.target.checked)} />
                                <span>my own face</span>
                            </div> */}
                        </div>
                        <Input className='face_name_input' placeholder={ownFaceFlag ? "My name" : "Owner of the face"}
                            value={ownerName} onChange={(e) => { setOwnerName(e.target.value) }} disabled={confirmed} />
                    </div>
                    {ownFaceFlag &&
                        <div className='face_info_input'>
                            <span>X Username:</span>
                            <Input className='face_name_input' placeholder="My X Username" value={xAccount} onChange={(e) => { setXAccount(e.target.value) }}
                                disabled={confirmed} />
                        </div>
                    }
                </div>
                {!ownFaceFlag &&
                    <div className='face_info_input_group'>
                        <div className='face_info_input'>
                            <div className='face_name_label'>
                                <span>Seeder:</span>
                            </div>
                            <Input className='face_name_input' placeholder="My name(as seeder)" value={sponsorName} onChange={(e) => { setSponsorName(e.target.value) }}
                                disabled={confirmed} />
                        </div>

                        <div className='face_info_input'>
                            <span>X Username:</span>
                            <Input className='face_name_input' placeholder="My X Username" value={xAccount} onChange={(e) => { setXAccount(e.target.value) }}
                                disabled={confirmed} />
                        </div>
                    </div>
                }
                <ImgCrop rotationSlider>
                    <Upload
                        name="face_img"
                        customRequest={handleUpload}
                        // action={process.env.REACT_APP_SERVER_ADDRESS + '/mock'}
                        // method="POST"
                        // data={{ 'face_id': id }}
                        // headers={{ 'Authorization': process.env.REACT_APP_AUTH_KEY }}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                        onCancel={handleCancel}
                        // onRemove={() => false}
                        disabled={confirmed}
                    >
                        {fileList.length < 1 && '+ Upload'}
                    </Upload>
                </ImgCrop>
                <div className="referrence_code">
                    <span className="referrence_code_label">Referrence Code:</span>
                    <Input placeholder="Referrence Code" className="referrence_code_input" onChange={handleRefferenceCode} value={referrenceCode} disabled={confirmed} />
                </div>
                <Button onClick={handleConfirm} disabled={!canConfirm() || confirmed}>confirm</Button>

                <p style={{ color: 'red' }}>{errMessage}</p>
                {fileList.length >= 1 && uploadImg !== undefined && confirmed &&
                    <>
                        {/* <PaypalCheckoutButton product={paypalProduct} faceId={id} img={uploadImg} closeModal={handleOk} /> */}
                        <StripeElement tabId={tabId}batchId={batchId} faceId={faceId} closeModal={handleOk} clearInput={clearInput}
                            priceInCent={priceInCent} setErrMessage={setErrMessage} stripeClientSecret={stripeClientSecret}
                            loadBatch={loadBatch} />
                    </>
                }
            </Modal>
        </>
    );
}
export default FaceUploadModal;