import { useState } from "react";
import { getBatchIds } from "../../data/faces";
// import Price from "./Price";
import FaceBatch from "./FaceBatch";
import FixedPrice from "./FixedPrice";
const FaceGrid = (props) => {
    const { tabId } = props;
    const batchIds = getBatchIds();
    const [currentPrice, setCurrentPrice] = useState(null);
    return (
        <div className="grid_wrapper">
            <div className={"face_grid"}>
                {/* <Price currentPrice={currentPrice} setCurrentPrice={setCurrentPrice} /> */}
                <FixedPrice currentPrice={currentPrice} setCurrentPrice={setCurrentPrice} />
                <div className="face-flex">
                    {/* {facesIds.map(id => {
                        return (<Face tabId={tabId} id={id} key={id} currentPrice={currentPrice} />)
                    })} */}
                    {batchIds.map(id =>
                        <FaceBatch tabId={tabId} id={id} key={id} currentPrice={currentPrice} />
                    )}
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                    <div style={{ width: '32px' }}></div>
                </div>
            </div>
        </div>
    )
}
export default FaceGrid;