import { useEffect } from "react";
import { getCurrentTime } from "../../data/time";

const FixedPrice = (props) => {
    const { currentPrice, setCurrentPrice } = props;

    useEffect(() => {
        let t;
        const init = async () => {
            const ret = await getCurrentTime()
            if (ret.success) {
                setCurrentPrice({
                    first: ret.data.current_price_1,
                    second: ret.data.current_price_2,
                    third: ret.data.current_price_3,
                })
            }
        }
        init();
        return () => {
            clearInterval(t)
        }
    }, [setCurrentPrice])

    return (
        <>
            <div className="price_grid">
                <p className="price_grid_text"></p>
                <div className="price_list">
                    <div className="price_list_item">
                        <span className="price_grid_text">Oliver </span>
                        <div className="price_info_color_first"></div>
                        <span className="price_grid_text"> ${currentPrice ? currentPrice.first : 5941}</span>
                    </div>
                    <div className="price_list_item">
                        <span className="price_grid_text">Mint </span>
                        <div className="price_info_color_second"></div>
                        <span className="price_grid_text"> ${currentPrice ? currentPrice.second : 941}</span>
                    </div>
                    <div className="price_list_item">
                        <span className="price_grid_text">Lychee </span>
                        <div className="price_info_color_third"></div>
                        <span className="price_grid_text"> ${currentPrice ? currentPrice.third : 94}</span>
                    </div>
                </div>

            </div>
            <div className="price_flex_mobile">
                <div className="price_list_item">
                    <span className="price_grid_text">Oliver </span>
                    <div className="price_info_color_first"></div>
                    <span className="price_grid_text"> ${currentPrice ? currentPrice.first : 5941}</span>
                </div>
                <div className="price_list_item">
                    <span className="price_grid_text">Mint </span>
                    <div className="price_info_color_second"></div>
                    <span className="price_grid_text"> ${currentPrice ? currentPrice.second : 941}</span>
                </div>
                <div className="price_list_item">
                    <span className="price_grid_text">Lychee </span>
                    <div className="price_info_color_third"></div>
                    <span className="price_grid_text"> ${currentPrice ? currentPrice.third : 94}</span>
                </div>
            </div>

        </>
    )
}
export default FixedPrice;