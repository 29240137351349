import Header from "./Header";
import { TypeAnimation } from 'react-type-animation';
import QA from "./QA";
const Home = () => {
    const typingStyle = window.innerWidth <= 800 ?
        { fontSize: '10vw', display: 'inline-block', fontFamily: 'YoungSerif', color: 'rgb(192, 234, 10, 1)' } :
        { fontSize: '5vw', display: 'inline-block', fontFamily: 'YoungSerif', color: 'rgb(192, 234, 10, 1)' }
    return (
        <>
            <div id="home">
                <Header />
                <div className="hero_img">
                    <div className="headline">
                        <p className="flow_slogan">FACE</p>
                        <TypeAnimation
                            sequence={[
                                'is Account',
                                2000,
                                'is Identity',
                                2000,
                                'is Wealth',
                                2000,
                                'is Teleport',
                                2000,
                            ]}
                            wrapper="span"
                            speed={50}
                            style={typingStyle}
                            repeat={Infinity}
                        />
                    </div>
                    <div className="hero_info">
                        Face.ai quantifies imagination, creativity, influence, and other fundamental values.
                    </div>
                    <div className="hero_info">
                        FACE is the rarest means of production, a transcendent teleport to AI world.
                    </div>
                    <a href="https://amaze.network/" target="_blank" rel="noreferrer" className="hero_info" style={{textDecoration:'none', padding: 0, margin: 0, width: '100%', display: "flex", alignItems: 'center', gap: '10px', justifyContent: 'center' }}>
                        <p> 👉download app</p>
                        <img src="/icon.png" width={40} alt="app" style={{borderRadius:'5px'}}/>
                    </a>
                </div>
                {/* <Inscription /> */}
                {/* <Intro />
                <KeyTech />
                <Seed />
                <RoadMap /> */}
                <QA />

            </div>

        </>
    )
}
export default Home;