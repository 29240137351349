import axios from "axios";
let reqInstance = axios.create({
    headers: {
        Authorization: process.env.REACT_APP_AUTH_KEY
    }
});


export const createIntent = async (formData) => {
    let ret
    try {
        const res = await reqInstance.post(process.env.REACT_APP_SERVER_ADDRESS + '/stripe/intent', formData);
        ret = { success: true, data: res.data.result }
    } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
            ret = { success: false, data: e.response.data.message }
        } else {
            ret = { success: false, data: "Failed to create intent" }
        }
    }
    return ret;
}

export const getStripeCheckoutURL = async (priceInCent) => {
    let res
    try {
        res = await reqInstance.get(process.env.REACT_APP_SERVER_ADDRESS + '/stripe/checkout?price_in_cent=' + priceInCent);
    } catch (e) {
        console.log(e)
    }
    // console.log(res);
    return res;
}